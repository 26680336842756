import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Utilities } from '@microsoft/office-js-helpers';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  showLoading$: EventEmitter<any> = new EventEmitter();
  currentLang = 'de';
  isOfficeApplication = false;

  constructor(private translate: TranslateService) {
    if (
      Utilities.host === 'EXCEL' ||
      Utilities.host === 'WORD' ||
      Utilities.host === 'POWERPOINT'
    ) {
      this.isOfficeApplication = true;
    }
  }

  public async notification(
    title: string,
    message: string,
    icon: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success'
  ) {
    return Swal.fire({ 
      toast: true, 
      position: 'top-end', 
      showConfirmButton: false, 
      timer: 3000, 
      timerProgressBar: true, 
      title: title, 
      html: message,
      icon: icon})
    //return Swal.fire(title, message, icon);
  }

  switchLanguage(lang: string) {
    this.currentLang = lang.toLowerCase();
    this.translate.use(this.currentLang);
  }
}
