<div class="container height-100 d-flex justify-content-center align-items-center">
  <div class="position-relative">
    <div class="card p-2 text-center">
      <h6 [innerHTML]="'otp.verifyheader' | translate"></h6>
      <div> <span translate="otp.codeSentTo"></span>
        {{phonenumber}} </div>
      <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
        <input class="m-2 text-center form-control rounded" type="text" id="first" maxlength="1" />
        <input class="m-2 text-center form-control rounded" type="text" id="second" maxlength="1" />
        <input class="m-2 text-center form-control rounded" type="text" id="third" maxlength="1" />
        <input class="m-2 text-center form-control rounded" type="text" id="fourth" maxlength="1" />
        <input class="m-2 text-center form-control rounded" type="text" id="fifth" maxlength="1" />
        <input class="m-2 text-center form-control rounded" type="text" id="sixth" maxlength="1" />
      </div>
      <div class="btn-group gap-2 mt-4">
        <button class="btn btn-success px-4" (click)="submitCode()">{{'otp.Validate' | translate}}</button>
        <button class="btn btn-danger px-4" (click)="activeModal.close()">Abbrechen</button>
      </div>
      
    </div>
    <!-- <div class="card-2">
      <div class="content d-flex justify-content-center align-items-center">
        <span>{{'otp.didntGetCode' | translate}}</span>
        <a href="#" class="text-decoration-none ms-3">
          {{'otp.resend' | translate}} (1/3)</a> </div>
    </div> -->
  </div>
</div>

