import { inject, Injectable } from '@angular/core';
import {
  applyActionCode,
  Auth,
  AuthCredential,
  confirmPasswordReset,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getMultiFactorResolver,
  IdTokenResult,
  MultiFactorResolver,
  OAuthProvider,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  User,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BackendService } from './backend.service';
import { environment } from '../../environments/environment';
import {
  BehaviorSubject,
  filter,
  first,
  firstValueFrom,
  from,
  Observable,
  of,
} from 'rxjs';
import { Customer } from '../core/models/customer';
import {
  Database,
  fromRef,
  object,
  onValue,
  query,
  ref,
  set,
  stateChanges,
} from '@angular/fire/database';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OtpComponent } from '../views/modals/otp/otp.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private db = inject(Database);
  public demoUser = false;
  public auth: Auth = inject(Auth);
  private modalService = inject(NgbModal);

  public user: Customer | User | null = null;
  public user$ = new BehaviorSubject<User | null>(null);
  public roles: Array<string> = [];
  public idTokenResult: IdTokenResult | null = null;
  public officeid: string | null = null;

  constructor(private router: Router, private backend: BackendService) {
    if (environment.useEmulators.auth && !this.auth.emulatorConfig) {
      console.log('auth emulator', this.auth);
      this.setupEmulators(this.auth);
      /* connectAuthEmulator(this.auth, 'http://localhost:9099', {
        disableWarnings: true,
      }); */
    }

    onAuthStateChanged(this.auth, async (user: User | null) => {
      if (!user) {
        this.user = null;
        console.log('Not logged In');
      } else {
        this.user = user;
        if (user.email == 'demoversion@gerichtskostenmarke.de') {
          this.demoUser = true;
        }
        this.user$.next(user);
        this.idTokenResult = await user.getIdTokenResult();
        this.listenForTokenRefresh(user.uid);
        if (!environment.production) {
          console.log('logged In', user);
          console.log('token', this.idTokenResult);
        }

        if (this.idTokenResult.claims['role']) {
          this.roles = this.idTokenResult.claims['role'] as Array<string>;
        }
        if (this.idTokenResult.claims['officeid']) {
          this.officeid = this.idTokenResult.claims['officeid'] as string;
        }
      }
    });
  }
  public async setupEmulators(auth: any) {
    const authUrl = 'http://localhost:9099';
    await fetch(authUrl);
    connectAuthEmulator(auth, 'http://localhost:9099', {
      disableWarnings: true,
    });
    // why? to make sure that emulator are loaded
  }


  public applyVerification(actionCode: string) {
    return applyActionCode(this.auth, actionCode);
  }

  confirmPasswordReset(oobCode: string, newPassword: string) {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  listenForTokenRefresh(uid: string) {
    const mref = ref(this.db, `metadata/${uid}`);
    onValue(mref, (snapshot) => {
      const snap = snapshot.val();
      const now = new Date().getTime() + 1 * 60 * 1000;
      if (snap && snap.refreshTime && snap.refreshTime < now) {
        this.updateFreshTime();
        this.refreshIdTokenResult();
      }
    });
  }

  updateFreshTime() {
    if (this.auth.currentUser) {
      const mref = ref(this.db, `metadata/${this.auth.currentUser.uid}`);
      const now = new Date().getTime();

      return set(mref, { refreshTime: now + 3 * 60 * 1000 });
    }
    return of(null);
  }
  public async refreshIdTokenResult() {
    if (this.auth.currentUser) {
      this.idTokenResult = await this.auth.currentUser?.getIdTokenResult(true);
      if (!environment.production) {
        console.log('refreshed token', this.idTokenResult);
      }

      if (this.idTokenResult.claims['role']) {
        this.roles = this.idTokenResult.claims['role'] as Array<string>;
      }
    }
  }

  public resendVerification() {
    if (!this.auth.currentUser) {
      return;
    }
    return sendEmailVerification(this.auth.currentUser);
  }
  public register(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  signInWithMicrosoft() {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: "consent",
      tenant: "f444b10a-3f67-4bf2-a27f-7cd7a93f91b1",
    });
    signInWithPopup(this.auth, provider)
      .then(async (user: any) => {
        console.log('loggend In');
        await this.router.navigate(['/home']);
      })
      .catch((error: any) => {
        this.handleAuthError(error);
      });
  }

  public login(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then(async (user: any) => {
        console.log('logged In');

        await this.router.navigate(['/home']);
      })
      .catch((error: any) => {
        console.log('error ', error.code, error.message);
        this.handleAuthError(error);
      });
  }

  async updatePassword(newpass: string) {
    const user = (await this.getCurrentUser()) as User;
    return updatePassword(user, newpass);
  }

  async handleAuthError(error: any, option?: any) {
    switch (error.code) {
      case 'auth/invalid-email':
        this.backend.notification('Fehler', 'Falsche E-Mail', 'error');
        break;

      case 'auth/user-not-found':
        this.backend.notification(
          'Fehler',
          'Kein Benutzer mit den Zugangsdaten gefunden!',
          'error'
        );
        break;
      case 'auth/invalid-credential':
        this.backend.notification(
          'Fehler',
          'Sie haben eine <b>falsche</b> Email oder ein <b>falsches</b> Passwort eingetragen!',
          'error'
        );
        break;
      case 'auth/weak-password':
        this.backend.notification(
          'Fehler',
          'Neues Passwort ist zu schwach!',
          'error'
        );
        break;
      case 'auth/wrong-password':
        this.backend.notification(
          'Fehler',
          'Sie haben eine <b>falsche</b> Email oder ein <b>falsches</b> Passwort eingetragen!',
          'error'
        );
        break;
      case 'auth/multi-factor-auth-required':
        const mfaResolver = getMultiFactorResolver(this.auth, error);
        console.log('mfaResolver', mfaResolver);
        const recaptchaVerifier = new RecaptchaVerifier(
          this.auth,
          '2fa-captcha',
          {"size": "invisible"}
        );
        const phoneInfoOptions = {
          multiFactorHint: mfaResolver.hints[0],
          session: mfaResolver.session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(this.auth);
        const verifyPhoneStr = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        if (verifyPhoneStr) {
          const result = await this.openOTPModal(
            (mfaResolver.hints[0] as PhoneMultiFactorInfo).phoneNumber
          );
          if (result) {
            const code = result;
            const cred = PhoneAuthProvider.credential(verifyPhoneStr, code);
            const multiFactorAssertion =
              PhoneMultiFactorGenerator.assertion(cred);
            const user = await mfaResolver.resolveSignIn(multiFactorAssertion);
            
            if (user) {
              if (option && option.type == 'changePassword') {
                await this.updatePassword(option.newpass);
                this.backend.notification(
                  'Gespeichert',
                  'Passwort wurde geändert!',
                  'success'
                );
                this.router.navigate(["/account/settings"]);
              } else {
                this.router.navigate(["/home"]);
              }
            } else {
              this.logout();
            }
          }
        }
        break;
      default:
        this.backend.notification(
          'Fehler',
          'Passwort konnte nicht gespeichert werden!',
          'error'
        );
        break;
    }
  }

  async openOTPModal(phonenumber: string) {
    const modalRef = this.modalService.open(OtpComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
      windowClass: 'modal-holder',
      beforeDismiss: () => {
        return false;
      },
      backdropClass: 'modal-backdrop',
      container: 'body',
      animation: true,
      scrollable: true,
      ariaLabelledBy: 'modal-basic-title',
      ariaDescribedBy: 'modal-basic-title',
    });
    modalRef.componentInstance.phonenumber = phonenumber;
    return modalRef.result;
  }

  public logout() {
    signOut(this.auth).then(() => {
      this.router.navigate(['/auth/login']);
    });
  }
  public resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }
  public getCurrentUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      return this.auth.currentUser
        ? resolve(this.auth.currentUser)
        : reject(new Error('No User signed in!'));
    });
  }

  EmailAuthProviderCredentials(email: string, password: string) {
    return EmailAuthProvider.credential(email, password);
  }
  reauthenticateWithCredential(user: User, credentials: AuthCredential) {
    return reauthenticateWithCredential(user, credentials);
  }
  waitForUser() {
    return firstValueFrom(
      this.user$.pipe(
        filter((user) => user !== null),
        first()
      )
    );
  }
}
