import { Component, inject, Input, OnInit } from '@angular/core';
import {
  multiFactor,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
} from '@angular/fire/auth';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../../service/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-otp',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.scss',
})
export class OtpComponent implements OnInit {
  @Input() phonenumber: any;
  hints: PhoneMultiFactorInfo[];
  auth = inject(AuthService);
  activeModal = inject(NgbActiveModal);
  recaptchaVerifier: any;
  verifyPhoneStr: string;
  code: string = "";
  constructor() { }

  async ngOnInit() {
    this.OTPInput();
    //this.recaptchaVerifier = new RecaptchaVerifier(this.auth.auth, '2fa-captcha', {size:'invisble'})

  }

  OTPInput() {
    const inputs: any = document.querySelectorAll('#otp > *[id]');
    for (let i = 0; i < inputs.length; i++) {
      //@ts-ignore
      inputs[i].addEventListener('keydown', (event: any) => {
        if (event.key === 'Backspace') {
          inputs[i].value = '';
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          }
        }
      });
    }
  }

  submitCode() {
    const inputs = document.querySelectorAll("#otp > input");
    inputs.forEach((i: any) => {      
      this.code = `${this.code}${i.value}`;
    })
    this.activeModal.close(this.code);
    
  }
}
